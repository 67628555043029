import { useEffect, useState } from "react";
import { Table, Button, Row, Col, Alert } from "react-bootstrap";
import * as FaIcon from 'react-icons/fa'
import { useNavigate } from "react-router-dom";
import { LoadingOverlay } from "../../../components";
import API from "../../../database/firebase";

const Properties = () => {
    const navigate = useNavigate();
    const [reloadPage, setReloadPage] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [alert, setAlert] = useState({
        show: false,
        variant: 'info',
        message: 'null'
    });

    const AlertDialog = (show, variant, message) => {
        setAlert({
            show: show,
            variant: variant,
            message: message
        })
    }

    useEffect(() => {
        setLoadingText('Carregando itens...');
        setLoading(true);
        (async () => {
            setData(await API.getProperties())
        })()
        setTimeout(() => setLoading(false), 2000);
    }, [reloadPage])

    const deleteItem = async (item) => {
        setLoadingText('Eliminando propiedad...');
        setLoading(true);

        await API.deleteChild('imageList', item.imagesId);
        const res = await API.deleteChild('properties', item.id);
        if (res) {
            setTimeout(() => {
                setLoading(false);
            }, 1000)
            AlertDialog(true, 'success', 'Elemento eliminado, la página se volverá a cargar.')
            setTimeout(() => {
                setReloadPage((prev) => prev += 1)
            }, 2000)
        } else {
            setTimeout(() => {
                setLoading(false);
            }, 1000)
            AlertDialog(true, 'danger', 'No se puede eliminar, inténtalo de nuevo más tarde.')
        }
    }

    return (
        <div>
        <LoadingOverlay show={loading} text={loadingText} />
        {
            alert.show && <Alert variant={alert.variant} onClose={() => setAlert({ show: false })} dismissible>
                <p>
                    {alert.message}
                </p>
            </Alert>
        }
        <Row>
            <Col>
                <Button variant="success" onClick={() => navigate('/addProperty')}>Añadir</Button>
            </Col>
        </Row>
        <Row className={'mt-3'}>
            <Col>
                {
                data ?
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Categoría</th>
                                <th>Precio</th>
                                <th>Ubicación</th>
                                <th>Imágenes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td data-label="Nombre" width={'100%'}>{item.title}</td>
                                            <td data-label="Categoría" width={'100%'}>{item.category === 'realState' ? 'Bienes raices' : 'Tierra'}</td>
                                            <td data-label="Precio" width={'100%'}>${item.price}</td>
                                            <td data-label="Ubicación" width={'100%'}>{item.localizationName}</td>
                                            <td data-label="Ubicación" width={'100%'}>
                                                <div className="table-images">
                                                    {item?.imageList?.map((img) => <img src={img} alt={item.title} />)}
                                                </div>
                                            </td>
                                            <td>
                                                <Button variant={'danger'} onClick={() => deleteItem(item)}><FaIcon.FaTrashAlt /></Button>
                                                <Button className="mx-2" variant={'warning'} onClick={() => navigate(`/addProperty?editId=${item.id}`)}><FaIcon.FaPencilAlt color="white"/></Button>
                                            </td>
                                        </tr> 
                                    )
                                })}
                        </tbody>
                    </Table>
                    :
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <span>Agrega un elemento para verlo</span>
                    </div>
                }
            </Col>
        </Row>
    </div>
    )
}

export default Properties;