import styled from 'styled-components'
import Theme from '../../common/Themes'
import API from '../../database/firebase'
import { removeSession } from '../../session'

const View = styled.div` 
    display: flex;
    flex-direction: row;
`

const Text = styled.span`
    font-size: 16px;
    margin-left: 5px;
    margin-right: 5px;
    color: ${Theme.getColor('TEXT_COLOR')};

`
const Link = styled.a`
    font-size: 16px;
    margin-left: 5px;
    margin-right: 5px;
`

const logOut = async () => {
    removeSession()
}

const ButtonUser = (props) => (
        <View>
            <Text>{props.text} / <Link href={'/login'} onClick={() => {logOut()}}>Sair</Link></Text>
        </View>
)

export default ButtonUser;