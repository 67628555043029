// Sessão do usuário no navegador em cookies


import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import API from '../database/firebase';

export const setSession = (session) => {
  console.log(session)
  Cookies.remove("session");
  Cookies.set("session", CryptoJS.AES.encrypt(JSON.stringify(session), 'PhysiqueLifePharma').toString());
};

export const getSession = () => {
  const sessionCookie = Cookies.get("session");

  if (sessionCookie === undefined) {
    return {};
  } else {
    const bytes = CryptoJS.AES.decrypt(sessionCookie, 'PhysiqueLifePharma');
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
};

export const removeSession = async () => {
  if (!await API.removeSession()) {
    alert('Não foi possível sair da sessão.')
  } else {
    Cookies.remove("session");
  }
  
};

export const getAuth = () => {
  const sessionCookie = Cookies.get("session");
  
  if (sessionCookie === undefined) {
    return false;
  } else {
    return true;
  }
};








