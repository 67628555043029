import styled from 'styled-components';
import Common from '../default';
import { SideBarData } from './SideBarData';
import '../default.css'
import $ from 'jquery'
import Theme from '../../common/Themes';


const DrawerItemIcon = 30;


const List = styled.ul`
  padding: 0;
  list-style-type: none;
  list-style: none;
  position: relative;
  cursor: pointer;
`;

const Link = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #e1e9fc;
  font-size: 18px;
  list-style: none;
  float: left;
  a {
    border-left: 4px solid transparent;
  }
  a:hover {
    border-left: ${(props => !props.route ? `4px solid ${Common.Color.PRIMARY} ` : 'none')};
  }  
`;

const Label = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #e1e9fc;
  text-decoration: none;
  font-size: ${(props => props.isSubMenu ? '14px' : '16px ')};
  padding: 12px 25px 12px 8px;
  padding-left: ${(props => !props.isSubMenu ? 8 : 0)};
  &:hover {
    background: ${Theme.getColor('HOVER_SIDEBAR')};
    
  };
  &:link:visited:hover {
    color: #e1e9fc;
  };

  
  ${(props => props.route ? `
    &:before {
      background: #dedede;
      bottom: auto;
      content: "";
      height: 8px;
      left: ${DrawerItemIcon -3}px;
      position: absolute;
      right: auto;
      width: 8px;
      z-index: 1;
      border-radius: 50%;
  }
    &:after {
      border-left: 1px solid #dedede;
      bottom: 0;
      content: "";
      left: ${DrawerItemIcon}px;
      position: absolute;
      z-index: 2;
      top: 0;
    }` :null)};
`;


const Item = (props) => {
  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: !props.isSubMenu ? '1.3rem' : 0}}>
      <div style={{marginLeft: 10, marginRight: 10, display: 'flex', alignItems: 'center'}}> 
        {props.src}
      </div> 
      <span style={{marginLeft: !props.src ? '0.8rem' : 0}}>{props.text}</span>
    </div>
  )
}

const NavMenu = (data) => { 
  const items = data.items;
  const isSubMenu = data.isSubMenu;
  return items.map((item, key) => {


    if (item.subMenu) {
      return ( 
        <Link key={key} route={item.route} isSubMenu={isSubMenu}>
          <Label route={item.route} className={'dropdown-toggle'} isSubMenu={!isSubMenu} data-toggle="collapse" data-target={`#${item.id}`} aria-expanded="true" aria-controls={item.id} onClick={() => {
              $(`#${item.id}`).toggleClass('show')}
              }>
              <Item src={item.icon} text={item.title} isSubMenu={isSubMenu} route={true}/>
            </Label>
          <List style={{paddingLeft: 0}} className="collapse show" id={item.id}> <NavMenu isSubMenu={false} items={item.subMenu}/></List>
        </Link> 
      )
    } else {
      return (
        <Link key={key} route={item.route} isSubMenu={false} >
          <Label href={item.route} route={item.route}>
            <Item src={item.icon} text={item.title} />
          </Label>
        </Link>
      )
    }

  });
}

const SideBar = () => {
  return (
    <List>
      <NavMenu isSubMenu={true} items={SideBarData} />
    </List>
  )
};

export default SideBar;
