import { Navigate, Outlet  } from "react-router-dom";
import { getAuth } from "../../session";
import MasterPage from "../MasterPage";

const ProtectedRoutes = props => {    
        if (!getAuth()) {
                return <Navigate to={'/login'} />
        }

        return <MasterPage><Outlet /></MasterPage>
}

export default ProtectedRoutes;