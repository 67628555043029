import styled from 'styled-components'
import { FaBars, FaTimes } from "react-icons/fa";
import Common from '../default';

const View = styled.button` 
    border-radius: 100px;
    border: 0;
    padding: 10px;
    background-color: ${Common.Color.PRIMARY};
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    color: #FFF;
    &:hover{
        background-color: ${Common.Color.PRIMARY_DARK};
    }
`;



const ButtonNavbar = (props) => (
   <View onClick={props.onClick}>
       <FaBars /> 
   </View>
)

export default ButtonNavbar;