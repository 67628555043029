import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
`;

export const Title = styled.span`
  font-size: 32px;
  font-weight: bold;

  @media (max-width: 700px) {
    font-size: 24px;
  }
  @media (max-width: 470px) {
    font-size: 18px;
  }
`;

export const Text = styled.a`
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  color: #283077;

  @media (max-width: 700px) {
    font-size: 20px;
  }
  @media (max-width: 470px) {
    font-size: 16px;
  }
  &:link {
    color: #283077;
  }
  &:visited {
    color: #283077;
  }
  &:hover {
    color: #201077;
  }
`