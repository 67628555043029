import styled, { keyframes } from 'styled-components';


const Overlay = styled.div` 
    position: fixed; 
    display: block; 
    width: 100%; 
    height: 100%; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer; 
`;

const Content = styled.div` 
    height: 100vh;
    width: 100vw;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const LoadingOverlay = (props) => {
    return (
        <>
            {props.show &&
            <Overlay>
                <Content>
                    <Spinner />
                    <span>{props.text}</span>
                </Content>
            </Overlay>
            }
        </>
    )
}

export default LoadingOverlay;