const ProjectInfo = {
  PORJECT_NAME: 'Admin',
}

const Color = {
    PRIMARY: 'rgb(201, 145, 85)',
    PRIMARY_DARK: 'rgb(168, 121, 70)',
    SECONDARY: '#323232',
    SECONDARY_DARK: '#242424',
    ERROR: 'rgba(244, 67, 54, 1.0)',
    BACKGROUND_COLOR: 'rgba(255,255,255, 1.0)',

    BUTTON_DEFAULT: 'rgba(0, 191, 165, 1.0)',
    BUTTON_SUCCESS: 'rgba(102, 187, 106, 1.0)',
    BUTTON_WARNING: 'rgba(255, 167, 38, 1.0)',
    BUTTON_ERROR: 'rgba(239, 83, 80, 1.0)',
  }

  const FontSize = {
    SMALL: 12,
    NORMAL: 14,
    MEDIUM: 16,
    BIG: 18,
    EXTRA_BIG: 20
  }

  const Sizes = {
    WIDTH_MOBILE: '768px',
    DRAWER_WIDTH: '280px',
    TOP_BAR_HEIGHT: '70px'
  }

  const Common = {ProjectInfo, Color, FontSize, Sizes};

  export default Common;

