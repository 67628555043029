import styled from "styled-components";
import Theme from "../../common/Themes";
import Common from '../default';

// Drawer

export const Overlay = styled.div` 
    @media (max-width:${Common.Sizes.WIDTH_MOBILE}) {
        display: ${(props => props.visible ? 'block' : 'none')}; 
        position: fixed; 
        width: 100%;
        height: 100%; 
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 1; 
        cursor: pointer; 
    }
`;

export const Drawer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: ${(props => props.visible ? '0px' : `-${Common.Sizes.DRAWER_WIDTH}`)};
    width: ${Common.Sizes.DrawerWidth};
    min-width: ${Common.Sizes.DRAWER_WIDTH};
    max-width: ${Common.Sizes.DRAWER_WIDTH};
    height: 100vh;
    background-color: ${Theme.getColor('SIDEBAR')};
    @media (max-width:${Common.Sizes.WIDTH_MOBILE}) {
        margin-left: ${(props => props.visible ? `-${Common.Sizes.DRAWER_WIDTH}` : '0px')};
        position: absolute;
        z-index: 999;
    }
`;

export const DrawerHeader = styled.div`
    display: flex;
    align-items: center;
    height: ${Common.Sizes.TOP_BAR_HEIGHT};
    padding: 20px;
`;

export const DrawerBody = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const DrawerFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${Common.Sizes.TOP_BAR_HEIGHT};
    width: 100%;
`;

export const DrawerTitle = styled.span`
    font-size: 2.0rem;
    color: ${Theme.getColor('TEXT_COLOR')};
`

// NavBar

export const NavBar = styled.div` 
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: ${Common.Sizes.TOP_BAR_HEIGHT};
    padding: 20px;
    background-color: ${Theme.getColor('NAVBAR')};
    box-shadow: 8px 0px 8px 0px rgba(0, 0, 0, .09);   
`;

export const Content = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${Theme.getColor('BACKGROUND')};
    overflow-y: auto;

    &::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: ${Theme.getColor('BACKGROUND')};
    }

    &::-webkit-scrollbar
    {
        width: 5px;
        background-color: ${Theme.getColor('BACKGROUND')};
    }

    &::-webkit-scrollbar-thumb
    {
        background-color: ${Theme.getColor('NAVBAR')};
    }
   
`;

export const PageContent = styled.div`
    padding: 32px;
    color: ${Theme.getColor('TEXT_COLOR')};
    @media (max-width: 768px) {
        padding: 16px;
    }
`;