import { Navigate, Outlet } from "react-router-dom"
import { getAuth } from "../../session";

const LoggedIn = () => {

    if (getAuth()) {
        return <Navigate to={'/'} />
    } 

    return <Outlet />
}

export default LoggedIn;