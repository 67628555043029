import { Routes, Route} from "react-router-dom";
import { ProtectedRoutes, LoggedIn } from "./components";

// import pages 

import Login from "./pages/login";
import Home from "./pages/home";
import Error404 from "./pages/errors/Error404";
import Properties from "./pages/modules/properties";
import AddProperty from "./pages/modules/properties/add";


const Routers = () => {
    return (
            <Routes>
                <Route path="/*" element={<Error404 />} />
                
                <Route element={<LoggedIn />}>
                    <Route exact path="login"  element={<Login />}/>
                </Route>

                <Route element={<ProtectedRoutes />}>
                    <Route exact path="/" element={<Home />}/>
                    <Route exact path="/properties" element={<Properties />}/>
                    <Route exact path="/addProperty" element={<AddProperty />}/>
                </Route>
            </Routes>      
        );  
    
    
}

export default Routers;