import { useEffect, useState } from "react";
import { ErrorInput, LoadingOverlay } from "../../../components";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import moment from 'moment';
import API from "../../../database/firebase";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { v4 } from 'uuid';
import { useLocation } from "react-router-dom";

const AddProperty = () => {
  const [loading, setLoading] = useState(false);
  const [categoryType, setCategoryType] = useState('realState');
  const [update, setUpdate] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [editId, setEditId] = useState(null);
  const [alert, setAlert] = useState({
    show: false,
    variant: "info",
    message: "null",
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    control,
  } = useForm({
    criteriaMode: "all",
  });

  const AlertDialog = (show, variant, message) => {
    setAlert({
      show: show,
      variant: variant,
      message: message,
    });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const editIdParam = urlParams.get('editId');

    if (editIdParam) {
      setEditId(editIdParam);
    }
  }, [])


  useEffect(() => {
    const fetch = async () => {
      const data = await API.getProperty(editId);

      setValue('category', data?.category, { shouldValidate: true });
      setValue('title', data.title, { shouldValidate: true });
      setValue('description', data.description, { shouldValidate: true });
      setValue('localizationName', data.localizationName, { shouldValidate: true });
      setValue('localizationUrl', data.localizationUrl, { shouldValidate: true });
      setValue('roomsQtd', data.roomsQtd, { shouldValidate: true });
      setValue('bathroomsQtd', data.bathroomsQtd, { shouldValidate: true });
      setValue('squareMeters', data.squareMeters, { shouldValidate: true });
      setValue('squareMetersBuilt', (data.squareMetersBuilt || 0), { shouldValidate: true });
      setValue('price', data.price, { shouldValidate: true });
      setValue('type', data.type, { shouldValidate: true });
      setValue('imagesId', data.imagesId, { shouldValidate: true });
    }

    if (editId) {
      fetch();
    }
  }, [update, editId, setValue])

  const add = async (data) => {
    setLoadingText('Agregando propiedad...');
    setLoading(true);
    const imagesChild = `IMG_${v4()}_${moment().valueOf()}`;

    for (const file of data.files) {
      const url = await API.uploadImage(file);

      if (!url) {
        setLoading(false);
        return AlertDialog(true, 'danger', 'No se puede agregar una imagen, inténtelo de nuevo más tarde.');
      }

      const img = await API.addImage(url, imagesChild);
      if (!img) {
        setLoading(false);
        return AlertDialog(true, 'danger', 'No se puede agregar una imagen, inténtelo de nuevo más tarde.');
      }
    }

    const payload = { ...data };

    payload.imagesId = imagesChild;
    delete payload.files;

    console.log(payload);

    const responseAddProperty = await API.addProperty(payload);

    if (!responseAddProperty) {
      return AlertDialog(true, 'danger', 'No se puede agregar una propiedad, inténtelo de nuevo más tarde');
    }

    reset();
    setLoading(false);
    return AlertDialog(true, 'success', 'Propiedad añadida con éxito');
  };

  const edit = async (data) => {
    setLoadingText('Editando propiedad...');
    setLoading(true);

    const payload = { ...data }

    const responseAddProperty = await API.editProperty(editId, payload);

    if (!responseAddProperty) {
      setLoading(false);
      return AlertDialog(true, 'danger', 'No se puede editar una propiedad, inténtelo de nuevo más tarde');
    }


    setLoading(false);
    setUpdate(true);
    return AlertDialog(true, 'success', 'Propiedad editada con éxito');
  }

  return (
    <div>
      <LoadingOverlay show={loading} text={loadingText} />
      {alert.show && (
        <Alert
          variant={alert.variant}
          onClose={() => setAlert({ show: false })}
          dismissible
        >
          <p>{alert.message}</p>
        </Alert>
      )}
      <div className="title">
        <span>{editId ? 'Editar propiedad' : 'Añadir propiedad'}</span>
      </div>
      <div>
        <Form onSubmit={handleSubmit(editId ? edit : add)}>
        <Form.Group>
          <Form.Label>Categoría</Form.Label>
          <Controller
            control={control}
            defaultValue="realState"
            name="category"
            render={({ field: { onChange, value } }) => (
              <Form.Select 
                value={value}
                onChange={(e) => {
                  setCategoryType(e.target.value);
                  onChange(e);
                }}>
                <option value="realState">Bienes raices</option>
                <option value="land">Tierra</option>
              </Form.Select>
            )}
          />
        </Form.Group>

          {categoryType === 'realState' ? (
            <Row className="mt-3">
              <Col className={"col-12 col-sm-12 col-md-8 col-lg-6"}>
                <Form.Group>
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type={"text"}
                    placeholder={"Escribe aquí.."}
                    {...register("title", {
                      required: "Todos los campos deben ser llenados",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="title"
                    render={({ messages }) => {
                      return <ErrorInput messages={messages}></ErrorInput>;
                    }}
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>Descripción</Form.Label>
                  <Form.Control
                    as="textarea"
                    row="3"
                    type={"text"}
                    placeholder={"Escribe aquí.."}
                    {...register("description", {
                      required: "Todos los campos deben ser llenados",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="description"
                    render={({ messages }) => {
                      return <ErrorInput messages={messages}></ErrorInput>;
                    }}
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>Nombre de la ciudad</Form.Label>
                  <Form.Control
                    type={"text"}
                    placeholder={"Escribe aquí.."}
                    {...register("localizationName", {
                      required: "Todos los campos deben ser llenados",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="localizationName"
                    render={({ messages }) => {
                      return <ErrorInput messages={messages}></ErrorInput>;
                    }}
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>Google Maps URL</Form.Label>
                  <Form.Control
                    type={"text"}
                    placeholder={"Escribe aquí.."}
                    {...register("localizationUrl", {
                      required: "Todos los campos deben ser llenados",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="localizationUrl"
                    render={({ messages }) => {
                      return <ErrorInput messages={messages}></ErrorInput>;
                    }}
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>Cantidad de habitaciones</Form.Label>
                  <Form.Control
                    type={"number"}
                    placeholder={"0"}
                    {...register("roomsQtd", {
                      required: "Todos los campos deben ser llenados",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="roomsQtd"
                    render={({ messages }) => {
                      return <ErrorInput messages={messages}></ErrorInput>;
                    }}
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>Cantidad de baños</Form.Label>
                  <Form.Control
                    type={"number"}
                    placeholder={"0"}
                    {...register("bathroomsQtd", {
                      required: "Todos los campos deben ser llenados",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="bathroomsQtd"
                    render={({ messages }) => {
                      return <ErrorInput messages={messages}></ErrorInput>;
                    }}
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>Metros cuadrados total</Form.Label>
                  <Form.Control
                    type={"number"}
                    step="0.010"
                    placeholder={"0"}
                    {...register("squareMeters", {
                      required: "Todos los campos deben ser llenados",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="squareMeters"
                    render={({ messages }) => {
                      return <ErrorInput messages={messages}></ErrorInput>;
                    }}
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>Metros cuadrados construidos</Form.Label>
                  <Form.Control
                    type={"number"}
                    step="0.010"
                    placeholder={"0"}
                    {...register("squareMetersBuilt", {
                      required: "Todos los campos deben ser llenados",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="squareMetersBuilt"
                    render={({ messages }) => {
                      return <ErrorInput messages={messages}></ErrorInput>;
                    }}
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>Precio</Form.Label>
                  <Form.Control
                    type={"number"}
                    step="0.010"
                    placeholder={"0"}
                    {...register("price", {
                      required: "Todos los campos deben ser llenados",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="price"
                    render={({ messages }) => {
                      return <ErrorInput messages={messages}></ErrorInput>;
                    }}
                  />
                </Form.Group>
              </Col>
              <Col className={"col-12 col-sm-12 col-md-8 col-lg-6"}>
                <Form.Group>
                  <Form.Label>Tipo</Form.Label>
                  <Form.Select
                    {...register("type", {
                      required: "Todos los campos deben ser llenados",
                    })}
                  >
                    <option value="sale">Venda</option>
                    <option value="rent">Alquiler</option>
                  </Form.Select>
                  <ErrorMessage
                    errors={errors}
                    name="type"
                    render={({ messages }) => {
                      return <ErrorInput messages={messages}></ErrorInput>;
                    }}
                  />
                </Form.Group>
                {!editId && <Form.Group className="mt-3">
                  <Form.Label>Images</Form.Label>
                  <Form.Control
                    type={"file"}
                    multiple
                    accept="image/*"
                    {...register("files", {
                      required: "Todos los campos deben ser llenados",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="files"
                    render={({ messages }) => {
                      return <ErrorInput messages={messages}></ErrorInput>;
                    }}
                  />
                </Form.Group>}
                <Form.Group className={"mt-3"}>
                  <Button type={"submit"} variant={editId ? 'warning' : 'primary'}>
                    {editId ? 'Editar' : 'Adicionar'}
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          )
            :
            (
              <Row className="mt-3">
                <Col className={"col-12 col-sm-12 col-md-8 col-lg-6"}>
                  <Form.Group>
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                      type={"text"}
                      placeholder={"Escribe aquí.."}
                      {...register("title", {
                        required: "Todos los campos deben ser llenados",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="text"
                      render={({ messages }) => {
                        return <ErrorInput messages={messages}></ErrorInput>;
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mt-3">
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control
                      as="textarea"
                      row="3"
                      type={"text"}
                      placeholder={"Escribe aquí.."}
                      {...register("description", {
                        required: "Todos los campos deben ser llenados",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="description"
                      render={({ messages }) => {
                        return <ErrorInput messages={messages}></ErrorInput>;
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mt-3">
                    <Form.Label>Nombre de la ciudad</Form.Label>
                    <Form.Control
                      type={"text"}
                      placeholder={"Escribe aquí.."}
                      {...register("localizationName", {
                        required: "Todos los campos deben ser llenados",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="localizationName"
                      render={({ messages }) => {
                        return <ErrorInput messages={messages}></ErrorInput>;
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mt-3">
                    <Form.Label>Google Maps URL</Form.Label>
                    <Form.Control
                      type={"text"}
                      placeholder={"Escribe aquí.."}
                      {...register("localizationUrl", {
                        required: "Todos los campos deben ser llenados",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="localizationUrl"
                      render={({ messages }) => {
                        return <ErrorInput messages={messages}></ErrorInput>;
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mt-3">
                    <Form.Label>Metros cuadrados</Form.Label>
                    <Form.Control
                      type={"number"}
                      step="0.010"
                      placeholder={"0"}
                      {...register("squareMeters", {
                        required: "Todos los campos deben ser llenados",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="squareMeters"
                      render={({ messages }) => {
                        return <ErrorInput messages={messages}></ErrorInput>;
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mt-3">
                    <Form.Label>Precio</Form.Label>
                    <Form.Control
                      type={"number"}
                      step="0.010"
                      placeholder={"0"}
                      {...register("price", {
                        required: "Todos los campos deben ser llenados",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="price"
                      render={({ messages }) => {
                        return <ErrorInput messages={messages}></ErrorInput>;
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col className={"col-12 col-sm-12 col-md-8 col-lg-6"}>
                  <Form.Group>
                    <Form.Label>Tipo</Form.Label>
                    <Form.Select
                      {...register("type", {
                        required: "Todos los campos deben ser llenados",
                      })}
                    >
                      <option value="sale">Venda</option>
                      <option value="rent">Alquilar</option>
                    </Form.Select>
                    <ErrorMessage
                      errors={errors}
                      name="type"
                      render={({ messages }) => {
                        return <ErrorInput messages={messages}></ErrorInput>;
                      }}
                    />
                  </Form.Group>
                  {!editId && <Form.Group className="mt-3">
                    <Form.Label>Images</Form.Label>
                    <Form.Control
                      type={"file"}
                      multiple
                      accept="image/*"
                      {...register("files", {
                        required: "Todos los campos deben ser llenados",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="files"
                      render={({ messages }) => {
                        return <ErrorInput messages={messages}></ErrorInput>;
                      }}
                    />
                  </Form.Group>}
                  <Form.Group className={"mt-3"}>
                    <Button type={"submit"} variant={editId ? 'warning' : 'primary'}>
                      {editId ? 'Editar' : 'Adicionar'}
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            )
          }
        </Form>
      </div>
    </div>
  );
};

export default AddProperty;
