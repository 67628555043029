const setTheme = (theme) => {
    localStorage.setItem("theme", theme);
    window.location.reload();
}

const getTheme = () => {
    return localStorage.getItem("theme");
}

const getColor = (key) => {
    const t = localStorage.getItem("theme") ?? 'light';
    return Themes[t][key];
}

const Themes = {
    light: {
        BACKGROUND: 'rgba(245, 245, 245, 1)',
        NAVBAR: 'rgba(255, 255, 255, 1)',
        SIDEBAR: 'rgba(28,28,28, 1)',
        COMPONENTS: 'rgba(255, 255, 255, 1)',
        COMPONENTS_LEVEL1: 'rgba(255, 255, 255, 1)',
        COMPONENTS_LEVEL2: 'rgba(245, 245, 245, 1)',
        HOVER_SIDEBAR: 'rgba(33, 32, 51, 1)',
        HOVER_MENU: 'rgba(200, 200, 200, 1)',
        ITEM_SELECTED: 'rgba(220, 220, 220, 1)',
        TEXT_COLOR: 'rgba(21,21,21, 1)',
    },
    dark:{
        BACKGROUND: 'rgba(17,27,33, 1)',
        NAVBAR: 'rgba(32,44,51, 1)',
        SIDEBAR: 'rgba(24,34,41, 1)',
        COMPONENTS: 'rgba(32,44,51, 1)',
        COMPONENTS_LEVEL1: 'rgba(32,44,51, 1)',
        COMPONENTS_LEVEL2: 'rgba(42, 57, 66, 1)',
        HOVER_SIDEBAR: 'rgba(114,112,154, 0.1)',
        HOVER_MENU: 'rgba(114,112,154, 0.1)',
        ITEM_SELECTED: 'rgba(24,34,41, 1)',
        TEXT_COLOR: 'rgba(233,237,239, 1)'
    }
}

const Theme = {getTheme, setTheme, getColor}

export default Theme;
