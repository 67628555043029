import React from 'react';
import * as MdIcons from 'react-icons/md';

export const SideBarData = [
  {
    title: 'Website',
    id: 'website',
    icon: <MdIcons.MdViewList />,
    subMenu: [
      {
        title: 'Propriedades',
        route: '/properties/'
      },
    ]
  }
];
