import React, {useState} from "react";
import { Overlay, Content, NavBar, PageContent, Drawer, DrawerHeader, DrawerBody, DrawerFooter, DrawerTitle } from './styles.js';
import { ButtonUser, ButtonNavbar, SideBar, Breadcrumbs } from "../index";
import { useLocation } from 'react-router-dom'
import Routers from "../../routers";
import { AppImages } from "../../common/AppImages.js";

const MasterPage = ({children}) => {
    const [show, setShow] = useState(true); 

    const toggleDrawer = () => {
        setShow(!show);
    }

    const location = useLocation();

    if (location.pathname === '/login') {
        return <Routers />
    } else {
        return (
            <div id="wrapper" className={'d-flex align-items-stretch'} style={{ overflowY: 'hidden',}}>
                <Overlay visible={!show} onClick={() => toggleDrawer()} />
                <Drawer id={'drawer'} visible={show} style={{color: '#fff'}}>
                    <DrawerHeader id={'drawerHeader'}>
                        <DrawerTitle><a href={'/'}><img height={300} width={250} style={{ objectFit: 'contain' }} src={AppImages.AppLogo} alt={'logo'}></img></a></DrawerTitle>
                    </DrawerHeader>
                    <DrawerBody>
                        <SideBar />
                    </DrawerBody>
                    <DrawerFooter>
                        Giovanna Aguilera © 2023
                    </DrawerFooter>
                </Drawer>
                <Content>
                <NavBar>
                    <ButtonNavbar onClick={() => toggleDrawer()}/>
                    <ButtonUser text={'Admin'}/>
                </NavBar>
                <PageContent>
                    {children}
                </PageContent>
                </Content>
            </div>
        );
    }

    
}

export default MasterPage;