import { Container, Title,Text } from "./styles";
import Lottie from 'react-lottie';
import { AppImages } from "../../../common/AppImages";
import i18n from 'i18n-js';

const Error404 = () => {
    
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: AppImages.LottieError,

      };

    return (
        <Container>
            <Lottie options={defaultOptions}
             style={{width: '50%', height: '30%'}}/>
             <Title>{i18n.t('error404.title')}</Title>
             <Text href="/">{i18n.t('error404.text')}</Text>
        </Container>
    );
}

export default Error404;