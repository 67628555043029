import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Common from "../../common/Common";
import { Card } from "../../components";
const Home = () => {

    useEffect(() => {
        document.title = `${Common.ProjectInfo.PORJECT_NAME} - Início`
    }, [])


    return (
        <div>
            <Row>
                <Col md="3">
                    <Card>
                        <h4>Visita el sitio web</h4>
                        <h5><a href={'https://giovannaaguilera.com/'} target="_blank" rel="noreferrer">www.giovannaaguilera.com</a></h5>
                    </Card>
                </Col>
                <Col md="3">
                    <Card>
                        <h4>Analytics</h4>
                        <h5><a href={'https://clarity.microsoft.com/projects/view/hfskm8vv8n/dashboard?date=Last%203%20days'} target="_blank" rel="noreferrer">clarity.microsoft.com</a></h5>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Home;