import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import i18n from '../../lang/index';
import { Container, Row, Col, Card, Button, Form, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { ErrorInput } from "../../components/index.js";
import $ from 'jquery';
import Common from "../../common/Common";
import API from '../../database/firebase';

const Login = () => {
    const navigate = useNavigate();
    const [reloadPage, setReloadPage] = useState(0);
    const [alertDangerShow, setAlertDangerShow] = useState(false);
    const [alertSuccessShow, setAlertSuccessShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState();

    useEffect(() => {
        document.title = `${Common.ProjectInfo.PORJECT_NAME} | Login`
    }, [])

    const {register, formState: { errors }, handleSubmit} = useForm({
        criteriaMode: "all"
      });

    const onSubmit = async (data) => {
        let response = await API.AuthenticateUser(data);
        if (response) {
            navigate('/')
        } else {
            setAlertMessage('Erro ao fazer login');
            setAlertDangerShow(true);
        }  
    }

    const forgotPassword = async () => {
        const email = $("input[name=email]").val();
            if (email.length >= 6) {
                const response = await API.FogotPassword(email);

                if (response) {
                    setAlertMessage('Foi enviado um email de recuperação para você');
                    setAlertSuccessShow(true)
                } else {
                    setAlertMessage('Erro ao recuperar senha');
                    setAlertDangerShow(true);
                }
        } else {
            setAlertMessage('Digite ao menos um email para recuperar sua senha');
            setAlertDangerShow(true);
        }
    }

    return(
        <Container className='d-flex justify-content-center align-items-center pt-5 px-4'>
            <Form onSubmit={handleSubmit(onSubmit)} className='col-12 col-sm-12 col-md-8 col-lg-4'>
                    <Card className='card-login'>
                        <Card.Header className='card-header d-flex justify-content-center align-items-center'>
                            <Card.Title style={{color: '#FFF'}}>Giovanna Aguilera - Admin</Card.Title>
                        </Card.Header>
                        <Card.Body className='card-body'>
                                    { alertDangerShow ? <Alert variant="danger">
                                           {alertMessage}
                                           <button type="button" className="btn-close" aria-label="Close alert" onClick={() => setAlertDangerShow(false)}></button>
                                    </Alert> : null }
                                    { alertSuccessShow ? <Alert variant="success">
                                           {alertMessage}
                                           <button type="button" className="btn-close" aria-label="Close alert" onClick={() => setAlertSuccessShow(false)}></button>
                                    </Alert> : null }

                                    <Form.Group className="mb-3">
                                        <Form.Control type="text" placeholder={i18n.t('login.username')}  {...register("email", {required: i18n.t('form.required')})}/>
                                        <ErrorMessage errors={errors} name="email" render={({ messages }) =>  {return ( <ErrorInput messages={messages}></ErrorInput>)}}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Control type="password" placeholder={i18n.t('login.password')} {...register("password", {required: i18n.t('form.required')})}/>
                                        <ErrorMessage errors={errors} name="password" render={({ messages }) =>  {return ( <ErrorInput messages={messages}></ErrorInput>)}}/>  
                                    </Form.Group>
                        </Card.Body>
                        <Card.Footer>
                            <Row className='d-flex justify-content-between pt-3'>
                                <Col className='d-flex justify-content-end mb-3'>
                                    <a href={'#'} onClick={() => forgotPassword()}>{i18n.t('login.forgotPassword')} </a>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Button type="submit" className="login-button">{i18n.t('buttons.login')}</Button>
                            </Row>
                        </Card.Footer>
                    </Card>
            </Form>
        </Container>
    )
}

export default Login;