import styled from 'styled-components';

const ImageSelector = styled.img`
    width: 400px;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.background};
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    color: #323232;
`;

export default ImageSelector;