import i18n from 'i18n-js'

import pt from './translations/pt.json'
import en from './translations/en.json'

i18n.defaultLocale = 'pt-BR'
i18n.locale = 'pt-BR'
i18n.fallbacks = true
i18n.translations = { pt, en }

export default i18n
