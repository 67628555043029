import { v4 } from 'uuid'
import firebase from "firebase/app";
import 'firebase/database'
import 'firebase/storage'
import 'firebase/auth'
import { setSession } from '../session';

const firebaseConfig = {
    apiKey: "AIzaSyCB7_ZeolbM149052wV8leqPbhwx3y1VR0",
    authDomain: "realtor-portfolio.firebaseapp.com",
    databaseURL: "https://realtor-portfolio-default-rtdb.firebaseio.com",
    projectId: "realtor-portfolio",
    storageBucket: "realtor-portfolio.appspot.com",
    messagingSenderId: "477336977424",
    appId: "1:477336977424:web:32a97e2a3f33db1b348a95",
    measurementId: "G-PHVL8JQ2VN"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);

const database = firebaseApp.database();
const storage = firebaseApp.storage().ref();
const auth = firebaseApp.auth();

const AuthenticateUser = async (data) => {
    return new Promise((resolve, reject) => {
        auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
           auth.signInWithEmailAndPassword(data.email, data.password)
            .then((userCredential) => {
                resolve(userCredential.user)
            })
            .catch((error) => {
                reject(null)
            });
        })
        .catch((error) => {
            console.log(error)
            reject(null)
        });
    }).then((data) => {
        setSession(data)
        return data;
    }).catch((error) => {
        return null;
    })
};

const FogotPassword = async (email) => {
    return new Promise((resolve, reject) => {
        auth.sendPasswordResetEmail(email)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject()
            });
    }).then(() => {
        return true;
    }).catch(() => {
        return false;
    })
};

const removeSession = async () => {
    return new Promise((resolve, reject) => {
        auth.setPersistence(firebase.auth.Auth.Persistence.NONE)
        .then(() => {
           auth.signOut()
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject()
            });
        })
        .catch((error) => {
            console.log(error)
            reject(null)
        });
    }).then(() => {
        return true;
    }).catch(() => {
        return false;
    })
};

const getProperties = async () => {
    const eventref = database.ref('properties');
    const snapshot = await eventref.once('value');
    const data = [];

    if (!snapshot.val()) {
        return;
    } 
    
    for (let key of Object.keys(snapshot.val())) { 
        const property = snapshot.val()[key];

        const imgRef = database.ref('imageList').child(property?.imagesId).orderByValue();
        const imgSnapshot = await imgRef.once('value');
  
        property.id = key;
  
        property.imageList = Object.keys(imgSnapshot.val()).map((imageKey) => imgSnapshot.val()[imageKey].image);
        delete property?.imagesId;

        data.push(property);
    }
    return data;
};

const getProperty = async (id) => {
    const eventref = database.ref('properties').child(id);
    const snapshot = await eventref.once('value');
    const property = snapshot.val();

    return property;
};

const addProperty = async (property) => {
    return new Promise((resolve, reject) => {
        database.ref('properties').push({ ...property }, (error) => {
            if (error) {
                reject()
            } else {
                resolve()
            }
        });
    }).then(() => {
        return true
    }).catch(() => {
        return false
    })
};

const editProperty = async (editId, property) => {
    return new Promise((resolve, reject) => {
        database.ref('properties').child(editId).set(property, (error) => {
            if (error) {
                reject()
            } else {
                resolve()
            }
        });
    }).then(() => {
        return true
    }).catch(() => {
        return false
    })
};

const addImage = async (url, child) => {
    return new Promise((resolve, reject) => {
        database.ref('imageList').child(child).push({ image: url }, (error) => {
            if (error) {
                reject()
            } else {
                resolve()
            }
        });
    }).then(() => {
        return true
    }).catch(() => {
        return false
    })
};

const uploadImage = async (file) => {
    return new Promise((resolve, reject) => {
        var thisRef = storage.child(`images/${v4()}`);

        thisRef.put(file).then(function (snapshot) {
            if (snapshot.state === "success") {
                resolve(snapshot.ref.getDownloadURL())
            } else {
                console.log(snapshot.state);
                reject()
            }
        }).catch((error) => console.log(error))
    }).then((data) => {
        return data;
    }).catch(() => {
        return null;
    })
};

const deleteChild = async (ref, child) => {
    return new Promise((resolve, reject) => {
        database.ref(ref).child(child).remove().then(() => {
            resolve()
        }).catch(function (error) {
                reject()
            });
    }).then(() => {
        return true;
    }).catch(() => {
        return false;
    })
};

const API = {
    AuthenticateUser,
    FogotPassword,
    removeSession,
    getProperties,
    getProperty,
    addProperty,
    editProperty,
    uploadImage,
    addImage,
    deleteChild,
}

export default API;


