import styled from 'styled-components';

const View = styled.div` 
    max-width: 310px;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: #fff;
    padding: 10px;
`;



const Card = ({children}) => {
    return (
        <View>
            {children}
        </View>
    )
}

export default Card;